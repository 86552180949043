import axios from "axios";
import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { Box, Skeleton, Typography } from "@mui/material";

import { API_BASE } from "../../App.js";
import { Whatshot } from "@mui/icons-material";

export default function LeaderboardTable({}) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getResults = async (e) => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "/data/leaderboard";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          // token: localStorage.getItem("accessToken"),
        },
      });

      setResults(response["data"]["results"]);
      // setDates(response["data"]["dates"]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  const staticColumns = [
    {
      field: "analyst",
      headerName: "Analyst",
      width: 140,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 100,
    },
    {
      field: "search",
      headerName: "Search",
      width: 250,
    },
    {
      field: "this_week",
      type: "number",
      width: 100,
      headerClassName: 'weekly--header',
      cellClassName: (params) => {
       if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_week",
      type: "number",
      width: 100,
      headerClassName: 'weekly--header',
      cellClassName: (params) => {
       if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_30_days",
      headerName: "30d",
      type: "number",
      width: 90,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value == 0) {
           return "number-is-zero";
         }
       },
    },
    {
      field: "approval_rate",
      headerName: "30d %",
      type: "number",
      width: 90,
      // valueFormatter: (params) => {
      //   return params.value ? params.value.toFixed(2) : "0.00";
      // },
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value <  0.7) {
           return "weekly--header";
         }
       },
    },
  ];

  const generateLast7Days = () => {
    const today = new Date();
    const last7Days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      last7Days.push(date.toISOString().split("T")[0]);
    }
    return last7Days;
  };
  
  const dates = generateLast7Days();
  
  const lastSevenDatesColumns = dates.map((date, index) => ({
    field: date,
    headerName: index === 0 ? "Today" : date,
    type: "number",
    headerClassName: index === 0 ? "today--header" : "daily--header",
    width: 120,
    cellClassName: (params) => {
      return clsx({
        "number-is-zero": params.value == 0,
      });
    },
  }));

  const trailingColumns = [
    {
      field: "last_7_days",
      headerName: "7d",
      type: "number",
      width: 90,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
       if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_14_days",
      headerName: "14d",
      type: "number",
      width: 90,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value == 0) {
           return "number-is-zero";
         }
       },
    },

    {
      field: "last_90_days",
      headerName: "90d",
      type: "number",
      width: 100,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value == 0) {
           return "number-is-zero";
         }
       },
    },
    {
      field: "last_180_days",
      headerName: "180d",
      type: "number",
      width: 90,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value == 0) {
           return "number-is-zero";
         }
       },
    },
    {
      field: "last_365_days",
      headerName: "365d",
      type: "number",
      width: 90,
      headerClassName: 'trailing--header',
      cellClassName: (params) => {
        if (params.value == 0) {
           return "number-is-zero";
         }
       },
    },
  ];

  const columns = [
    ...staticColumns,
    ...lastSevenDatesColumns,
    ...trailingColumns,
  ];

  const aggregationModel = dates.reduce(
    (model, date) => {
      model[date] = "sum";
      return model;
    },
    {
      last_7_days: "sum",
      last_14_days: "sum",
      last_30_days: "sum",
      last_90_days: "sum",
      last_180_days: "sum",
      last_365_days: "sum",
      this_week: "sum",
      last_week: "sum",
      approval_rate: "mean", 
    }
  );

  return (
    <Box
      sx={{
        px: 4,
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
        "& .trailing--header": {
          color: "#1976d2",
        },
        "& .weekly--header": {
          color: "#FB5A45",
        },
        "& .today--header": {
          color: "#04FFFF",
        },
        "& .daily--header": {
          color: "#bdbdbd",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Whatshot color="secondary" sx={{ fontSize: 24 }} />
        <Typography variant="h5" sx={{ my: 2 }}>
          Leaderboard
        </Typography>
      </Box>

      {loading && (
        <Skeleton
          variant="rectangular"
          height={540}
          style={{ borderRadius: "5px" }}
        />
      )}

      {!loading && (
        <DataGridPremium
          rows={results}
          columns={columns}
          getRowId={(row) => row.index}
          density="compact"
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 100]}
          unstable_cellSelection
          unstable_ignoreValueFormatterDuringExport
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },
            "& .MuiDataGrid-aggregationColumnHeader": {
              minHeight: "50px",
            },
            "& .MuiDataGrid-footerCell": {
              color: "secondary.main",
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: { sortModel: [{ field: dates[0], sort: "desc" }] },
            // rowGrouping: { model: ["analyst", "stage", "search"] },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "approval_rate",
                    operator: ">",
                    value: 0,
                  },
                  
                ],
              },
            },
            // columns: {
            //   columnVisibilityModel: {
            //     analyst: false,
            //     stage: false,
            //     search: false,
            //   },
            // },
            aggregation: {
              model: aggregationModel,
            },
          }}
        />
      )}
    </Box>
  );
}
