import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { AutoAwesome, LinkedIn } from "@mui/icons-material";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";

import Highlighter from "react-highlight-words";

import EventButtonsContainer from "./EventButtonsContainer";
import OpenDealCloudContainer from "./OpenDealCloudContainer";

import ChipRow from "./ChipRow";

import CommentsContainer from "../CommentsContainer";

import { API_BASE } from "../../../App";
import RatingContainer from "../../../components/RatingContainer";

export default function TargetCard({
  searchData,
  target,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  const [display, setDisplay] = useState("");
  const [loading, setLoading] = useState();

  const enrichTarget = async () => {
    const endpoint = API_BASE + "enrich";
    if (target.enriched) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(endpoint, {
        domain: target.domain,
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
      });

      setTargets((prevTargets) =>
        prevTargets.map((t) => {
          if (t.domain === target.domain) {
            return response.data;
          } else {
            return t;
          }
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    enrichTarget();
  }, []);

  return (
    <Card
      variant="outlined"
      style={{ display: display }}
      key={target.domain}
      sx={{ p: 4, my: 4 }}
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* <IconButton onClick={enrichTarget}>
          <AutoAwesome sx={{ fontSize: "0.8em", mr: 1 }} color="secondary" />
        </IconButton> */}
        <Box id="company">
          <Typography variant="h5" sx={{mb: 1, fontWeight: 600}}>{target.name}</Typography>
          {target["linkedin"] && (
            <IconButton href={target["linkedin"]} target="_blank"sx={{p: 0}}>
              <LinkedIn sx={{ fontSize: "20px", mr: 1, opacity: 0.7 }} />
            </IconButton>
          )}
          <Link
            href={`https://www.${target.domain}`}
            target="_blank"
            color="primary"
          >
            {target.domain}
          </Link>
          
        </Box>

        <EventButtonsContainer
          target={target}
          searchData={searchData}
          targets={targets}
          setTargets={setTargets}
          setDisplay={setDisplay}
          fetchTargetCount={fetchTargetCount}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ChipRow searchData={searchData} target={target} />
          <WasAcquired target={target} />
          {/* <ContactRow target={target} /> */}
          <TargetDescription target={target} />
        </>
      )}

      <CardActions
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <RatingContainer
          target={target}
          targets={targets}
          setTargets={setTargets}
          setDisplay={setDisplay}
          fetchTargetCount={fetchTargetCount}
        />
      </CardActions>

      <Box>
        <CommentsContainer searchData={searchData} target={target} />
      </Box>
    </Card>
  );
}

function TargetDescription({ target }) {
  return (
    <Box id="target-description" style={{ wordWrap: "break-word" }}>
      <AutoAwesome sx={{ fontSize: "0.8em", mr: 1 }} color="secondary" />
      <Highlighter
        // searchWords={keywords || []}
        searchWords={[]}
        autoEscape={true}
        textToHighlight={target.description || ""}
      />
    </Box>
  );
}

function ContactRow({ target }) {
  const contactString = () => {
    return [
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
      "contact_address",
    ]
      .map((key) => {
        if (target[key] != "") {
          return (
            <span key={key}>
              {target[key]}
              {" • "}
            </span>
          );
        }
      })
      .join("");
  };

  const showContact = () => {
    console.log(contactString());
    return contactString() && contactString() != "";
  };

  return (
    <>
      {showContact() && (
        <Box
          style={{
            margin: "20px 5px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactEmergencyIcon color="info" />
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {[
              "contact_name",
              "contact_title",
              "contact_email",
              "contact_phone",
              "contact_address",
            ].map((key) => {
              if (target[key] != "") {
                return (
                  <span key={key}>
                    {target[key]}
                    {" • "}
                  </span>
                );
              }
            })}
          </Typography>
        </Box>
      )}
    </>
  );
}

function WasAcquired({ target }) {
  return (
    <>
      {target.meta.was_acquired != "" && (
        <Box style={{ marginBottom: "10px", width: "100%" }}>
          <Typography variant="caption" color="lightgray">
            was_acquired
          </Typography>
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {target.meta.was_acquired}
          </Typography>
          <Typography variant="caption" style={{ marginLeft: "15px" }}>
            {target.meta.justification}
          </Typography>
        </Box>
      )}
    </>
  );
}
