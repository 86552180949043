import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  AppBar,
  Box,
  Checkbox,
  Container,
  Button,
  Autocomplete,
  FormControlLabel,
  MenuItem,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { ArrowBackIos, ArrowUpward, ImportExport } from "@mui/icons-material";

import { LABEL_MAP, ICON_MAP, SOURCE_OPTIONS } from "../../fragments/Constants";
import { API_BASE } from "../../App";

export default function Import() {
  const searchUid = useSearchParams()[0].get("searchKey");

  const [loading, setLoading] = useState(false);
  const [csv, setCsv] = useState("");

  const [domains, setDomains] = useState([]);
  const [targets, setTargets] = useState([]);
  const [imports, setImports] = useState([]);

  const [source, setSource] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [conflicts, setConflicts] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [targetStage, setTargetStage] = useState("land");

  const [pastSearch, setPastSearch] = useState(null);

  const [searches, setSearches] = useState([]);

  const getSearchData = async (e) => {
    try {
      const response = await axios.get(API_BASE + "search", {
        params: {
          search_uid: searchUid,
        },
      });
      setSearchData(response.data);
    } catch (error) {
      console.error(error);
      // setError(error.message);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  const checkDomains = async () => {
    try {
      setLoading(true);
      const domainsArray = csv
        .split("\n")
        .filter((domain) => domain.trim() !== "");
      const response = await axios.post(API_BASE + "check_domains", {
        search_uid: searchUid,
        domains: domainsArray,
      });
      // setDomains(response.data);
      // [{"example.com": "land"}, {"example2.com": "create"}]
      setImports(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (csv) {
      checkDomains();
    }
  }, [csv]);

  const handleCheckboxChange = (event, domain) => {
    if (event.target.checked) {
      setDomains((prevDomains) => [...prevDomains, domain]);
    } else {
      setDomains((prevDomains) =>
        prevDomains.filter((item) => item !== domain)
      );
    }
  };

  const importTargets = async () => {
    try {
      setLoading(true);

      const response = await axios.post(API_BASE + "import", {
        search_uid: searchUid,
        domains: domains,
        stage: targetStage,
        source: source.join(", "), // tbd

        actor_key: localStorage.getItem("actorKey"),
      });

      window.location.href = `/search?searchKey=${searchUid}&currentView=${targetStage}`;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getSearches = async (e) => {
      try {
        setLoading(true);
        const endpoint = API_BASE + "searches";
        const response = await axios.get(endpoint, {
          params: {
            actor_key: localStorage.getItem("actorKey"),
            token: localStorage.getItem("accessToken"),
          },
        });

        setSearches(
          response.data.searches.filter((search) => search.uid != searchUid)
        );
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    getSearches();
  }, []);

  const fetchPastSearchDomains = async () => {
    const response = await axios.get(API_BASE + "search_target_domains", {
      params: {
        search_uid: pastSearch.uid,
        actor_key: localStorage.getItem("actorKey"),
      },
    });
    // return response.data.domains;
    setCsv(response.data.domains.join("\n"));
  };

  useEffect(() => {
    if (pastSearch) {
      fetchPastSearchDomains();
    }
  }, [pastSearch]);

  const groupedConflicts = conflicts
    ? conflicts.reduce((acc, conflict) => {
        if (!acc[conflict.stage]) {
          acc[conflict.stage] = [];
        }
        acc[conflict.stage].push(conflict);
        return acc;
      }, {})
    : {};

  return (
    <>
      <PageAppBar
        searchData={searchData}
        domains={domains}
        importTargets={importTargets}
        loading={loading}
      />
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Box style={{ display: "flex", justifyContent: "center", gap: 10 }}>
          <Box sx={{ width: "400px" }}>
            <TextField
              select
              value={targetStage}
              onChange={(e) => setTargetStage(e.target.value)}
              required
              label="Stage"
              variant="outlined"
              fullWidth
              sx={{ my: 2 }}
            >
              {Object.keys(LABEL_MAP).map((key) => (
                <MenuItem key={key} value={key}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    {ICON_MAP[key]}
                    {LABEL_MAP[key]}
                  </Box>
                </MenuItem>
              ))}
            </TextField>

            <Autocomplete
              // multiple
              // freeSolo
              value={pastSearch}
              onChange={(e, newValue) => setPastSearch(newValue)}
              options={searches}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Past Search"
                  variant="outlined"
                  helperText="Imports all targets other than: Landing, Inbox, & Reject."
                  // required
                  sx={{ my: 2 }}
                />
              )}
            />

            <Autocomplete
              multiple
              freeSolo
              value={source}
              onChange={(e, newValue) => setSource(newValue)}
              options={SOURCE_OPTIONS}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Data Source"
                  variant="outlined"
                  helperText="Where did this come from? Select or type + enter."
                  // required
                  sx={{ my: 2 }}
                />
              )}
            />

            <TextField
              value={csv}
              onChange={(e) => setCsv(e.target.value)}
              required
              // disabled={loading}
              helperText="Add one domain per line / paste from spreadsheet"
              label="Copy/paste domain(s) here"
              autoComplete="off"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
            />
            <TextField
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              fullWidth
              helperText="Describe the search criteria used to generate this list"
              label="Add a comment..."
              multiline
              minRows={2}
              autoComplete="off"
              variant="outlined"
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
          </Box>

          {Object.keys(groupedConflicts).length > 0 && (
            <Box sx={{ px: 4 }}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Conflicts</Typography>
                <Typography variant="caption">
                  Select to overwrite current stage.
                </Typography>
              </Box>

              {Object.keys(groupedConflicts).map((key) => (
                <Box key={key} sx={{ mt: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {ICON_MAP[key]}
                    <Typography variant="h6">{LABEL_MAP[key]}</Typography>
                  </Box>
                  {groupedConflicts[key].map((conflict) => (
                    <Box key={conflict["domain"]}>
                      <FormControlLabel
                        sx={{ color: "darkgrey" }}
                        control={
                          <Checkbox
                            checked={domains.includes(conflict.domain)}
                            onChange={(event) =>
                              handleCheckboxChange(event, conflict.domain)
                            }
                          />
                        }
                        label={conflict.domain}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}

function PageAppBar({ searchData, domains, importTargets, loading }) {
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="md">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ImportExport />
            <Typography variant="h6" sx={{ my: 4 }}>
              Import to {searchData?.label}
            </Typography>
          </Box>

          <Box style={{ display: "flex", alignItems: "center" }}>
            {domains?.length > 0 && (
              <Typography sx={{ mr: 2 }}>
                import {domains.length} targets
              </Typography>
            )}

            <Button
              onClick={importTargets}
              variant="contained"
              color="primary"
              startIcon={<ArrowUpward />}
              disabled={domains.length === 0 || loading}
              style={{ borderRadius: "30px" }}
            >
              Import
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
