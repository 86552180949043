import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Add, AutoAwesome, Cancel, Close } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { API_BASE } from "../../App.js";
import { COUNTRIES, OWNERSHIP, STATES } from "../../fragments/Constants";

export default function GrataDrawer({
  searchData,
  open,
  setOpen,
  fetchTargets,
  fetchTargetCount,
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [criteria, setCriteria] = useState(searchData.criteria);

  const location = useLocation();

  const runCriteriaSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(API_BASE + "criteria_search", {
        search_uid: searchData.uid,
        data: {
          criteria: criteria,
        },
      });

      // if currentView=create not in URL, navigate to search results
      
      if (!location.pathname.includes("create")) {
        window.location.href = `search?searchKey=${searchData.uid}&currentView=create`
      }
      console.log("Search Results:", response.data);
      fetchTargetCount();
      fetchTargets();
    } catch (error) {
      console.error("Error running criteria search:", error);
      setError("Failed to run search. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      style={{ zIndex: 64 }}
    >
      <PageAppBar
        open={open}
        setOpen={setOpen}
        runCriteriaSearch={runCriteriaSearch}
        loading={loading}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: 500,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box id="main-drawer-body" sx={{ px: 4, width: 500, pb: 10 }}>
          <Typography variant="caption" color="darkgrey">
            Find the next 25 companies not already in the Search. Results
            delivered to Inbox, Filters applied to Similarity Search.
          </Typography>

          <SearchTerms criteria={criteria} setCriteria={setCriteria} />
          <Card elevation={2} sx={{ p: 2, my: 2, borderRadius: 2 }}>
            <Ownership criteria={criteria} setCriteria={setCriteria} />
            <EmployeeRange criteria={criteria} setCriteria={setCriteria} />
          </Card>

          <Headquarters criteria={criteria} setCriteria={setCriteria} />
          <SearchTermsExclude criteria={criteria} setCriteria={setCriteria} />
        </Box>
      )}
    </Drawer>
  );
}

function Headquarters({ criteria, setCriteria }) {
  const handleInputChange = (event, field, index) => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include[index][field] = event.target.value;
    setCriteria(newCriteria);
  };

  const addNewLocation = () => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include.push({
      country: "United States",
      state: "",
      city: "",
    });
    setCriteria(newCriteria);
  };

  const removeLocation = (index) => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include.splice(index, 1);
    setCriteria(newCriteria);
  };

  return (
    <Card elevation={2} sx={{ p: 2, my: 2, borderRadius: 2 }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Headquarters</Typography>
          <Button
            variant="text"
            onClick={addNewLocation}
            startIcon={<Add />}
            color="info"
          >
            Add New Location
          </Button>
        </Box>

        {criteria.headquarters.include.map((location, index) => (
          <Card
            key={index}
            sx={{
              mt: 2,
              px: 2,
              py: 2,
              position: "relative",
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={() => removeLocation(index)}
              sx={{ position: "absolute", top: 0, right: 8 }}
            >
              <Cancel sx={{ fontSize: 16, opacity: 0.5 }} />
            </IconButton>

            <Box sx={{ my: 2 }}>
              <TextField
                select
                label="Country"
                value={location.country}
                onChange={(event) => handleInputChange(event, "country", index)}
                fullWidth
              >
                {Object.values(COUNTRIES).map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {location.country === "United States" && (
              <>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    select
                    label="State"
                    value={location.state || ""}
                    onChange={(event) =>
                      handleInputChange(event, "state", index)
                    }
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.values(STATES).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                {location.state && (
                  <TextField
                    label="City"
                    value={location.city || ""}
                    onChange={(event) =>
                      handleInputChange(event, "city", index)
                    }
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                )}
              </>
            )}
          </Card>
        ))}
      </Box>
    </Card>
  );
}

function Ownership({ criteria, setCriteria }) {
  const handleOwnershipChange = (event, value) => {
    setCriteria({
      ...criteria,
      ownership: value,
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Autocomplete
        multiple
        options={OWNERSHIP}
        value={criteria.ownership}
        onChange={handleOwnershipChange}
        renderInput={(params) => <TextField {...params} label="Ownership" />}
        getOptionLabel={(option) => option}
      />
    </Box>
  );
}

function EmployeeRange({ criteria, setCriteria }) {
  const handleRangeChange = (event, index) => {
    const newRange = [...criteria.grata_employees_estimates_range];
    newRange[index] = event.target.value ? parseInt(event.target.value, 10) : 0;
    setCriteria({
      ...criteria,
      grata_employees_estimates_range: newRange,
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ ml: 1, color: "darkgrey" }} variant="caption">
        Employee Estimate Range
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
        <TextField
          label="Min"
          type="number"
          value={criteria.grata_employees_estimates_range[0]}
          onChange={(event) => handleRangeChange(event, 0)}
          fullWidth
        />
        <TextField
          label="Max"
          type="number"
          value={criteria.grata_employees_estimates_range[1]}
          onChange={(event) => handleRangeChange(event, 1)}
          fullWidth
        />
      </Box>
    </Box>
  );
}

function SearchTerms({ criteria, setCriteria }) {
  const handleInputChange = (event, field, index) => {
    const newCriteria = { ...criteria };
    if (field === "terms") {
      newCriteria.terms_include.groups[index].terms = event.target.value
        .split(",")
        .map((term) => term.trim());
    } else if (field === "terms_depth") {
      newCriteria.terms_include.groups[index].terms_depth = event.target.value;
    } else if (field === "terms_operator") {
      newCriteria.terms_include.groups[index].terms_operator =
        event.target.value;
    } else if (field === "group_operator") {
      newCriteria.terms_include.group_operator = event.target.value;
    }
    setCriteria(newCriteria);
  };
  const addNewGroup = () => {
    const newCriteria = { ...criteria };
    newCriteria.terms_include.groups.push({
      terms: [],
      terms_depth: "core",
      terms_operator: "any",
    });
    setCriteria(newCriteria);
  };

  const removeGroup = (index) => {
    const newCriteria = { ...criteria };
    newCriteria.terms_include.groups.splice(index, 1);
    setCriteria(newCriteria);
  };

  return (
    <Card elevation={2} sx={{ p: 2, my: 2, borderRadius: 2 }}>
      {/* Terms Include Group */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Search Terms</Typography>
          <Button
            variant="text"
            onClick={addNewGroup}
            startIcon={<Add />}
            color="info"
          >
            Add New Group
          </Button>
        </Box>

        {criteria.terms_include.groups.map((group, index) => (
          <Card
            key={index}
            sx={{
              mt: 2,
              px: 2,
              py: 2,
              position: "relative",
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={() => removeGroup(index)}
              sx={{ position: "absolute", top: 0, right: 8 }}
            >
              <Cancel sx={{ fontSize: 16, opacity: 0.5 }} />
            </IconButton>
            <TextField
              label="Terms (comma-separated)"
              variant="outlined"
              fullWidth
              value={group.terms.join(", ")}
              onChange={(event) => handleInputChange(event, "terms", index)}
              sx={{ my: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <TextField
                select
                label="Terms Depth"
                size="small"
                value={group.terms_depth}
                onChange={(event) =>
                  handleInputChange(event, "terms_depth", index)
                }
                fullWidth
              >
                <MenuItem value="core">Core</MenuItem>
                <MenuItem value="secondary">Secondary</MenuItem>
              </TextField>
              <TextField
                select
                label="Terms Operator"
                size="small"
                value={group.terms_operator}
                onChange={(event) =>
                  handleInputChange(event, "terms_operator", index)
                }
                fullWidth
              >
                <MenuItem value="any">Any</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </Box>
          </Card>
        ))}
      </Box>

      {/* Group Operator */}
      <Box sx={{ mt: 4 }}>
        <TextField
          select
          size="small"
          label="Group Operator"
          value={criteria.terms_include.group_operator}
          onChange={(event) => handleInputChange(event, "group_operator")}
          sx={{ mb: 2, width: "100%" }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="any">Any</MenuItem>
        </TextField>
      </Box>

      {/* Add New Group Button */}
    </Card>
  );
}

function SearchTermsExclude({ criteria, setCriteria }) {
  const handleInputChange = (event, field, index) => {
    const newCriteria = { ...criteria };
    if (field === "terms") {
      newCriteria.terms_exclude[index].terms = event.target.value
        .split(",")
        .map((term) => term.trim());
    }
    setCriteria(newCriteria);
  };

  const addNewExcludeGroup = () => {
    const newCriteria = { ...criteria };
    newCriteria.terms_exclude.push({
      terms: [],
    });
    setCriteria(newCriteria);
  };

  const removeExcludeGroup = (index) => {
    const newCriteria = { ...criteria };
    newCriteria.terms_exclude.splice(index, 1);
    setCriteria(newCriteria);
  };

  return (
    <Card elevation={2} sx={{ p: 2, my: 2, borderRadius: 2 }}>
      {/* Terms Exclude Group */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Exclude Terms</Typography>
          <Button
            variant="text"
            onClick={addNewExcludeGroup}
            startIcon={<Add />}
            color="info"
          >
            Add Exlusion Terms
          </Button>
        </Box>

        {criteria.terms_exclude.map((group, index) => (
          <Card
            key={index}
            sx={{
              mt: 2,
              px: 2,
              py: 2,
              position: "relative",
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={() => removeExcludeGroup(index)}
              sx={{ position: "absolute", top: 0, right: 8 }}
            >
              <Cancel sx={{ fontSize: 16, opacity: 0.5 }} />
            </IconButton>
            <TextField
              label="Terms to Exclude (comma-separated)"
              variant="outlined"
              fullWidth
              value={group.terms.join(", ")}
              onChange={(event) => handleInputChange(event, "terms", index)}
              sx={{ my: 2 }}
            />
          </Card>
        ))}
      </Box>
    </Card>
  );
}

function PageAppBar({ open, setOpen, runCriteriaSearch, loading }) {
  return (
    <AppBar position="static" variant="transparent">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h6" component="div">
            Grata+ Search
          </Typography>
        </Box>

        <Button
          variant="contained"
          onClick={runCriteriaSearch}
          style={{ borderRadius: "20px" }}
          startIcon={<AutoAwesome />}
          disabled={loading}
        >
          Go
        </Button>
      </Toolbar>
    </AppBar>
  );
}
