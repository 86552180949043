import React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import {
  AccountBalance,
  AutoAwesome,
  CheckCircle,
  Flag,
  FlagOutlined,
  Inbox,
  MailOutline,
  Paragliding,
  RemoveCircleOutlined,
  RemoveCircleOutlineOutlined,
  StopCircle,
  Sync,
  ThumbUp,
  VerifiedUser,
} from "@mui/icons-material";

import { LABEL_MAP } from "../../fragments/Constants";

export default function LeftDrawer({ searchData, currentView, targetCount }) {
  const setView = (name) => {
    window.location.href = `/search?searchKey=${searchData["uid"]}&currentView=${name}`;
  };

  const ICON_MAP = {
    land: <Paragliding />,
    create: <Inbox />,
    similar: <AutoAwesome />,
    advance: <ThumbUp />,
    hold: <StopCircle />,
    validate: <VerifiedUser />,
    send: <MailOutline />,
    client_approve: <CheckCircle />,
    sync: <Sync />,
    reject: <RemoveCircleOutlineOutlined />,
    client_reject: <RemoveCircleOutlined />,
    conflict: <FlagOutlined />,
    client_conflict: <Flag />,
    buyer: <AccountBalance />,
  };

  const getColor = (key) => (currentView === key ? "primary" : "inherit");

  return (
    <Box
      id="left-drawer-main"
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: 150,
      }}
    >
      <List>
        {Object.keys(LABEL_MAP).map((key) => (
          <ListItem key={key}>
            <Tooltip title={LABEL_MAP[key]} placement="right-start">
              <ListItemButton
                onClick={() => setView(key)}
                selected={currentView === key}
              >
                <ListItemIcon sx={{ color: getColor(key) }}>
                  {React.cloneElement(ICON_MAP[key], { color: getColor(key) })}
                </ListItemIcon>
                <ListItemText
                  primary={targetCount[key]}
                  primaryTypographyProps={{
                    color: getColor(key),
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}